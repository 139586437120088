<template>
  <section class="impressum-section row-24 mx-0 text-white">
    <div class="col-22 offset-1 md:col-16 md:offset-5 lg:col-14">
      <p class="impressum-content" v-html="payload.impressumtext" />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    payload: Object,
  },
};
</script>

<style lang="scss" scoped>
.impressum {
  &-section {
    padding-top: 8rem;
    padding-bottom: 15rem;
  }
}

:deep(.impressum-content) {
  text-align: center;
  @screen sm {
    text-align: left;
  }
  h2, h3, h4, h5, h6 {
    margin: 0;
    line-height: 1;
  }
}
</style>
